import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  TableCell,
  TableBody,
  Fade,
  Tooltip,
  CardActions,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import {
  Search,
  FileDownload,
  MonitorHeart,
  EditNote,
  Assignment,
  Collections,
} from "@mui/icons-material";
import { debounce } from "lodash";
import AddNewImageResult from "../offcanvas/AddNewResult";
import { getResults } from "../repositories/ResultsRepository";
import SnackBarMessage from "../../components/SnackBarMessage";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EC7BA1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  " & th:first-child tr, & th:first-child": {
    borderTopLeftRadius: "50px",
  },
  " & th:first-child tr, & th:last-child": {
    borderTopRightRadius: "50px",
  },
  " &: th:last-child tr, & th:first-child": {
    borderBottomLeftRadius: "50px",
  },
  " &: th:last-child tr, &:last-child": {
    borderBottomRightRadius: "50px",
  },
}));

const CommonResultsEvents = () => {
  const [resultList, setResultList] = useState([]);
  const [resultFilterList, setResultFilterList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openResultCanvas, setOpenResultCanvas] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [resultSelected, setResultSelected] = useState(null);
  const navigate = useNavigate();

  const onlyClose = () => {
    setOpenResultCanvas(false);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - resultFilterList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const response = await getResults();

    if (response) {
      if (response.status === "Ok") {
        setResultList(response.content);
        setResultFilterList(response.content);
      } else if(response.status === "401") {
        setResultList([]);
        setResultFilterList([]);
        navigate(`../error401`);
      } else {
        setResultList([]);
        setResultFilterList([]);
      }
    }
  };

  const onCloseSuccess = (value) => {
    var list = [];

    for (var i = 0; i < resultFilterList.length; i++) {
      var item = resultFilterList[i];
      if (item._id !== value) {
        list.push(item);
      }
    }

    setResultList(list);
    setResultFilterList(list);
    setSnackSeverity("success");
    setSnackMessage("Se guardó correctamente");
    setSnackOpen(true);
  };

  const delayedHandleSearch = debounce(async (search) => {
    let newSearch = search
      .replace("á", "a")
      .replace("é", "e")
      .replace("í", "i")
      .replace("ó", "o")
      .replace("ú", "u");
    if (newSearch !== "") {
      const filterResult = Object.keys(resultList)
        .filter(
          (key) =>
            resultList[key].name
              .toString()
              .toLowerCase()
              .replace("á", "a")
              .replace("é", "e")
              .replace("í", "i")
              .replace("ó", "o")
              .replace("ú", "u")
              .includes(newSearch) ||
              resultList[key].lastname
              .toString()
              .toLowerCase()
              .replace("á", "a")
              .replace("é", "e")
              .replace("í", "i")
              .replace("ó", "o")
              .replace("ú", "u")
              .includes(newSearch)
        )
        .map((i) => resultList[i]);
      setResultFilterList(filterResult);
    } else {
      setResultFilterList(resultList);
    }
  }, 800);

  return (
    <div>
      <div id="div-style-8">
        <MonitorHeart color={"primary"} fontSize="large" id="icon-style-1" />
        <h2> Resultados </h2>
      </div>
      <Card id="card-style-2">
        <CardContent>
          <Grid container spacing={2} id="grid-style-2">
            <Grid sm={6}>
              <Paper component="form" id="paper-style-2">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Buscar..."
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={(event) => {
                    delayedHandleSearch(event.target.value.toLowerCase());
                  }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton type="button" id="icon-style-2" aria-label="search">
                  <Search color="primary" />
                </IconButton>
              </Paper>
            </Grid>
            <Grid sm={6} id="grid-style-3">
              <Button component="label" variant="outlined" id="button-style-3">
                <FileDownload /> Descargar
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <TableContainer id="table-container-style-1">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">No.</StyledTableCell>
                    <StyledTableCell>Nombre</StyledTableCell>
                    <StyledTableCell align="center">Edad</StyledTableCell>
                    <StyledTableCell>Género</StyledTableCell>
                    <StyledTableCell>Teléfono</StyledTableCell>
                    <StyledTableCell>Correo</StyledTableCell>
                    <StyledTableCell>Motivo</StyledTableCell>
                    <StyledTableCell>Estudio</StyledTableCell>
                    <StyledTableCell align="center">Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultFilterList &&
                    resultFilterList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="center">
                              {row.id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {`${row.name} ${row.lastname}`}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.age}
                            </StyledTableCell>
                            <StyledTableCell>{row.gender}</StyledTableCell>
                            <StyledTableCell>{row.phone}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>
                              {row.receptionData.reason}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.receptionData.studyType}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <Tooltip
                                title="Ver expediente"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  color="primary"
                                  disabled
                                >
                                  <Assignment fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Editar expediente"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  color="secondary"
                                  disabled
                                >
                                  <EditNote fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Archivar expediente"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  onClick={() => {
                                    setResultSelected(row);
                                    setOpenResultCanvas(true);
                                  }}
                                >
                                  <Collections
                                    fontSize="medium"
                                    sx={{ color: "#6F34D5" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
        <CardActions>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={resultFilterList.length}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Resultados por página:"}
          />
        </CardActions>
      </Card>

      <AddNewImageResult
        patientItem={resultSelected}
        onlyClose={onlyClose}
        openCanvas={openResultCanvas}
        closeSuccess={onCloseSuccess}
      />

      <SnackBarMessage
        severity={snackSeverity}
        message={snackMessage}
        open={snackOpen}
        onClose={() => {
          setSnackOpen(false);
        }}
      />
    </div>
  );
};

export default CommonResultsEvents;
