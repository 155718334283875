import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Card,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Paper,
  IconButton,
  Divider,
  Button,
  CardContent,
  CardActions,
  Tooltip,
  Fade,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import { debounce } from "lodash";
import {
  Search,
  FileDownload,
  MedicalInformation,
  EditNote,
  Assignment,
  Print,
} from "@mui/icons-material";
import AddNewSheet from "../offcanvas/AddNewSheet";
import { getSheets, getPatientSheet } from "../repositories/SheetRepository";
import SnackBarMessage from "../../components/SnackBarMessage";
import { generateEducativeOfferPdf } from "../../Util/ExportFiles/ClinicalSheet";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EC7BA1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  " &:first-child tr, &:first-child": {
    borderTopLeftRadius: "50px",
  },
  " &:first-child tr, &:last-child": {
    borderTopRightRadius: "50px",
  },
  " &:last-child tr, &:first-child": {
    borderBottomLeftRadius: "50px",
  },
  " &:last-child tr, &:last-child": {
    borderBottomRightRadius: "50px",
  },
}));

const CommonSheetEvents = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [patientList, setPatientList] = useState([]);
  const [patientFilterList, setPatientFilterList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openPatientCanvas, setOpenPatientCanvas] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [itemPatient, setItemPatient] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [onlyShow, setOnlyShow] = useState(false);

  const snackStatus = (value) => {
    if (value) {
      setSnackSeverity("success");
      setSnackMessage("La acción se ejecutó con éxito");
      setSnackOpen(true);
    } else {
      setSnackSeverity("error");
      setSnackMessage("Error al ejecutar la acción");
      setSnackOpen(true);
    }
  };
  const onlyClose = () => {
    setOpenPatientCanvas(false);
    getData();
  };
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - patientFilterList.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClickOpen = (patientData) => {
    setOpenPatientCanvas(true);
    setItemToEdit(patientData);
  };

  const printStatus = (value) => {
    if (value === "Hoja capturada") {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const response = await getSheets();
    if (response) {
      if (response.status === "Ok") {
        setPatientList(response.content);
        setPatientFilterList(response.content);
      } else if (response.status === "401") {
        setPatientList([]);
        setPatientFilterList([]);
        navigate(`../error401`);
      } else {
        setPatientList([]);
        setPatientFilterList([]);
      }
    }

    try {
      if (location.state.item !== null) {
        let found = false;

        for (let q = 0; q < response.content.length; q += 1) {
          const itemQ = response.content[q];

          if (itemQ._id === location.state.item._id) {
            found = true;
            q = response.content.length;
            setItemPatient(itemQ);
            setOpenPatientCanvas(true);
          }
        }

        if (!found) {
          setItemPatient(location.state.item);
          setOpenPatientCanvas(true);
        }
      }
    } catch (e) {}
  };

  const delayedHandleSearch = debounce(async (search) => {
    let newSearch = search
      .replace("á", "a")
      .replace("é", "e")
      .replace("í", "i")
      .replace("ó", "o")
      .replace("ú", "u");
    if (newSearch !== "") {
      const filterResult = Object.keys(patientList)
        .filter(
          (key) =>
            patientList[key].name
              .toString()
              .toLowerCase()
              .replace("á", "a")
              .replace("é", "e")
              .replace("í", "i")
              .replace("ó", "o")
              .replace("ú", "u")
              .includes(newSearch) ||
            patientList[key].lastname
              .toString()
              .toLowerCase()
              .replace("á", "a")
              .replace("é", "e")
              .replace("í", "i")
              .replace("ó", "o")
              .replace("ú", "u")
              .includes(newSearch)
        )
        .map((i) => patientList[i]);
      setPatientFilterList(filterResult);
    } else {
      setPatientFilterList(patientList);
    }
  }, 800);

  return (
    <div>
      <div id="div-style-8">
        <MedicalInformation
          color={"primary"}
          fontSize="large"
          id="icon-style-1"
        />
        <h2>Hoja clínica</h2>
      </div>
      <Card id="card-style-2">
        <CardContent>
          <Grid container spacing={2} id="grid-style-2">
            <Grid sm={6}>
              <Paper component="form" id="paper-style-2">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Buscar..."
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={(event) => {
                    delayedHandleSearch(event.target.value.toLowerCase());
                  }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton type="button" id="icon-style-2" aria-label="search">
                  <Search color="primary" />
                </IconButton>
              </Paper>
            </Grid>
            <Grid sm={6} id="grid-style-3">
              <Button
                component="label"
                size="small"
                variant="outlined"
                id="button-style-3"
              >
                <FileDownload /> Descargar
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <TableContainer id="table-container-style-1">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">No.</StyledTableCell>
                    <StyledTableCell>Nombre</StyledTableCell>
                    <StyledTableCell align="center">Edad</StyledTableCell>
                    <StyledTableCell>Género</StyledTableCell>
                    <StyledTableCell>Teléfono</StyledTableCell>
                    <StyledTableCell>Correo</StyledTableCell>
                    <StyledTableCell>Motivo</StyledTableCell>
                    <StyledTableCell>Tipo de estudio</StyledTableCell>
                    <StyledTableCell>Fundación</StyledTableCell>
                    <StyledTableCell>Estado</StyledTableCell>
                    <StyledTableCell align="center">Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientFilterList &&
                    patientFilterList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="center">
                              {row.id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {`${row.name} ${row.lastname}`}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.age}
                            </StyledTableCell>
                            <StyledTableCell>{row.gender}</StyledTableCell>
                            <StyledTableCell>{row.phone}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>
                              {row.receptionData[0]?.reason}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.receptionData[0]?.studyType}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.receptionData[0]?.foundationName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.generalStatus}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip
                                title="Ver expediente"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    setOnlyShow(true);
                                    handleClickOpen(row);
                                  }}
                                >
                                  <Assignment fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Editar expediente"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  color="secondary"
                                  onClick={() => {
                                    setOnlyShow(false);
                                    handleClickOpen(row);
                                  }}
                                >
                                  <EditNote fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Imprimir hoja clínica"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  color="info"
                                  disabled={printStatus(row.generalStatus)}
                                  onClick={async () => {
                                    const patientSheet = await getPatientSheet(
                                      row._id
                                    );
                                    generateEducativeOfferPdf(
                                      row,
                                      patientSheet
                                    );
                                  }}
                                >
                                  <Print fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
        <CardActions>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={patientFilterList.length}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Resultados por página:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        </CardActions>
      </Card>

      <AddNewSheet
        openCanvas={openPatientCanvas}
        onlyClose={onlyClose}
        itemPatient={itemPatient}
        itemToEdit={itemToEdit}
        snackStatus={snackStatus}
        showItems={onlyShow}
      />
      <SnackBarMessage
        severity={snackSeverity}
        message={snackMessage}
        open={snackOpen}
        onClose={() => {
          setSnackOpen(false);
        }}
      />
    </div>
  );
};

export default CommonSheetEvents;
